import React from 'react'
import ServicesHeading from '../components/ServicesHeading'

import DividerIcon from '../components/DividerIcon'
import PreFooter from '../components/PreFooter'


const DistanciaEP = props => {
    React.useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (

        <div>
            <ServicesHeading classToAdd="entregas" subtitle={"La calidad es nuestra primera prioridad."} title={"Atendemos en todos los puertos y fábricas del cordón industrial."} />
            <DividerIcon />
            <div className="mt-5 mx-5" style={{overflowX:"scroll"}}>


                <table id="tablepress-5" class="tablepress tablepress-id-5">
                    <thead>
                        <tr class="row-1 odd">
                            <th class="column-1">&nbsp;</th><th class="column-2">RENOVA (TIMBUES)</th><th class="column-3">LDC (TIMBUES)</th><th class="column-4">COFCO (TIMBUES)</th><th class="column-5">TERMINAL 6</th><th class="column-6">CARGILL (QUEBRACHO)</th><th class="column-7">COFCO (PSM)</th><th class="column-8">BUYATTI</th><th class="column-9">BUNGE (PSM)</th><th class="column-10">ADM AGRO (PSM)</th><th class="column-11">ACA</th><th class="column-12">MOLINOS AGRO (S. LORENZO)</th><th class="column-13">VICENTIN (PUERTO)</th><th class="column-14">VICENTIN (FABRICA)</th><th class="column-15">MOLCA (ROSARIO)</th><th class="column-16">BUNGE (SAN JERONIMO)</th><th class="column-17">PLAYA PEREZ</th><th class="column-18">MOLINOS AGRO (ROSARIO)</th><th class="column-19">UNIDAD 6 Y 7</th><th class="column-20">CARGILL (ALVEAR)</th><th class="column-21">LDC (LAGOS)</th><th class="column-22">ADM AGRO (A. SECO)</th><th class="column-23">V. CONSTITUCION</th><th class="column-24">SAN NICOLAS</th><th class="column-25">BUNGE (RAMALLO)</th><th class="column-26">SAN PEDRO</th><th class="column-27">COFCO (LIMA)</th>
                        </tr>
                    </thead>
                    
                    <tbody class="row-hover">
                        <tr class="row-2 even">
                            <td class="column-1">RENOVA (TIMBUES)</td><td class="column-2"></td><td class="column-3">4</td><td class="column-4">5</td><td class="column-5">22</td><td class="column-6">23</td><td class="column-7">28</td><td class="column-8">28</td><td class="column-9">32</td><td class="column-10">32</td><td class="column-11">34</td><td class="column-12">40</td><td class="column-13">40</td><td class="column-14">35</td><td class="column-15">81</td><td class="column-16">72</td><td class="column-17">69</td><td class="column-18">71</td><td class="column-19">83</td><td class="column-20">85</td><td class="column-21">96</td><td class="column-22">101</td><td class="column-23">133</td><td class="column-24">158</td><td class="column-25">178</td><td class="column-26">248</td><td class="column-27">308</td>
                        </tr>
                        <tr class="row-3 odd">
                            <td class="column-1">LDC (TIMBUES)</td><td class="column-2">4</td><td class="column-3"></td><td class="column-4">1</td><td class="column-5">18</td><td class="column-6">19</td><td class="column-7">24</td><td class="column-8">24</td><td class="column-9">28</td><td class="column-10">28</td><td class="column-11">30</td><td class="column-12">36</td><td class="column-13">36</td><td class="column-14">31</td><td class="column-15">77</td><td class="column-16">68</td><td class="column-17">65</td><td class="column-18">67</td><td class="column-19">79</td><td class="column-20">81</td><td class="column-21">92</td><td class="column-22">94</td><td class="column-23">129</td><td class="column-24">154</td><td class="column-25">174</td><td class="column-26">244</td><td class="column-27">304</td>
                        </tr>
                        <tr class="row-4 even">
                            <td class="column-1">COFCO (TIMBUES)</td><td class="column-2">5</td><td class="column-3">1</td><td class="column-4"></td><td class="column-5">17</td><td class="column-6">18</td><td class="column-7">23</td><td class="column-8">23</td><td class="column-9">27</td><td class="column-10">27</td><td class="column-11">29</td><td class="column-12">35</td><td class="column-13">35</td><td class="column-14">30</td><td class="column-15">76</td><td class="column-16">67</td><td class="column-17">64</td><td class="column-18">66</td><td class="column-19">78</td><td class="column-20">80</td><td class="column-21">91</td><td class="column-22">93</td><td class="column-23">128</td><td class="column-24">153</td><td class="column-25">173</td><td class="column-26">243</td><td class="column-27">303</td>
                        </tr>
                        <tr class="row-5 odd">
                            <td class="column-1">TERMINAL 6</td><td class="column-2">22</td><td class="column-3">18</td><td class="column-4">17</td><td class="column-5"></td><td class="column-6">1</td><td class="column-7">6</td><td class="column-8">6</td><td class="column-9">10</td><td class="column-10">10</td><td class="column-11">12</td><td class="column-12">18</td><td class="column-13">18</td><td class="column-14">13</td><td class="column-15">59</td><td class="column-16">50</td><td class="column-17">47</td><td class="column-18">49</td><td class="column-19">61</td><td class="column-20">63</td><td class="column-21">74</td><td class="column-22">76</td><td class="column-23">111</td><td class="column-24">136</td><td class="column-25">156</td><td class="column-26">226</td><td class="column-27">286</td>
                        </tr>
                        <tr class="row-6 even">
                            <td class="column-1">CARGILL (QUEBRACHO)</td><td class="column-2">23</td><td class="column-3">19</td><td class="column-4">18</td><td class="column-5">1</td><td class="column-6"></td><td class="column-7">5</td><td class="column-8">5</td><td class="column-9">9</td><td class="column-10">9</td><td class="column-11">11</td><td class="column-12">17</td><td class="column-13">17</td><td class="column-14">12</td><td class="column-15">58</td><td class="column-16">49</td><td class="column-17">46</td><td class="column-18">48</td><td class="column-19">60</td><td class="column-20">62</td><td class="column-21">73</td><td class="column-22">75</td><td class="column-23">100</td><td class="column-24">135</td><td class="column-25">155</td><td class="column-26">225</td><td class="column-27">285</td>
                        </tr>
                        <tr class="row-7 odd">
                            <td class="column-1">COFCO (PSM)</td><td class="column-2">28</td><td class="column-3">24</td><td class="column-4">23</td><td class="column-5">6</td><td class="column-6">5</td><td class="column-7"></td><td class="column-8">1</td><td class="column-9">9</td><td class="column-10">9</td><td class="column-11">11</td><td class="column-12">17</td><td class="column-13">17</td><td class="column-14">12</td><td class="column-15">58</td><td class="column-16">49</td><td class="column-17">46</td><td class="column-18">48</td><td class="column-19">60</td><td class="column-20">62</td><td class="column-21">73</td><td class="column-22">75</td><td class="column-23">100</td><td class="column-24">135</td><td class="column-25">155</td><td class="column-26">220</td><td class="column-27">280</td>
                        </tr>
                        <tr class="row-8 even">
                            <td class="column-1">BUYATTI</td><td class="column-2">28</td><td class="column-3">24</td><td class="column-4">23</td><td class="column-5">6</td><td class="column-6">5</td><td class="column-7">1</td><td class="column-8"></td><td class="column-9">2</td><td class="column-10">2</td><td class="column-11">5</td><td class="column-12">11</td><td class="column-13">11</td><td class="column-14">8</td><td class="column-15">54</td><td class="column-16">44</td><td class="column-17">41</td><td class="column-18">33</td><td class="column-19">52</td><td class="column-20">55</td><td class="column-21">68</td><td class="column-22">70</td><td class="column-23">95</td><td class="column-24">130</td><td class="column-25">150</td><td class="column-26">220</td><td class="column-27">280</td>
                        </tr>
                        <tr class="row-9 odd">
                            <td class="column-1">BUNGE (PSM)</td><td class="column-2">32</td><td class="column-3">28</td><td class="column-4">27</td><td class="column-5">10</td><td class="column-6">9</td><td class="column-7">9</td><td class="column-8">2</td><td class="column-9"></td><td class="column-10">2</td><td class="column-11">4</td><td class="column-12">12</td><td class="column-13">12</td><td class="column-14">9</td><td class="column-15">55</td><td class="column-16">45</td><td class="column-17">42</td><td class="column-18">44</td><td class="column-19">56</td><td class="column-20">58</td><td class="column-21">69</td><td class="column-22">71</td><td class="column-23">96</td><td class="column-24">131</td><td class="column-25">151</td><td class="column-26">221</td><td class="column-27">281</td>
                        </tr>
                        <tr class="row-10 even">
                            <td class="column-1">ADM AGRO ( PSM)</td><td class="column-2">32</td><td class="column-3">28</td><td class="column-4">27</td><td class="column-5">10</td><td class="column-6">9</td><td class="column-7">9</td><td class="column-8">2</td><td class="column-9">2</td><td class="column-10"></td><td class="column-11">4</td><td class="column-12">12</td><td class="column-13">12</td><td class="column-14">9</td><td class="column-15">55</td><td class="column-16">45</td><td class="column-17">42</td><td class="column-18">44</td><td class="column-19">56</td><td class="column-20">58</td><td class="column-21">69</td><td class="column-22">71</td><td class="column-23">96</td><td class="column-24">131</td><td class="column-25">151</td><td class="column-26">221</td><td class="column-27">281</td>
                        </tr>
                        <tr class="row-11 odd">
                            <td class="column-1">ACA</td><td class="column-2">34</td><td class="column-3">30</td><td class="column-4">29</td><td class="column-5">12</td><td class="column-6">11</td><td class="column-7">11</td><td class="column-8">5</td><td class="column-9">4</td><td class="column-10">4</td><td class="column-11"></td><td class="column-12">12</td><td class="column-13">12</td><td class="column-14">9</td><td class="column-15">53</td><td class="column-16">43</td><td class="column-17">40</td><td class="column-18">42</td><td class="column-19">54</td><td class="column-20">56</td><td class="column-21">67</td><td class="column-22">69</td><td class="column-23">94</td><td class="column-24">129</td><td class="column-25">149</td><td class="column-26">219</td><td class="column-27">279</td>
                        </tr>
                        <tr class="row-12 even">
                            <td class="column-1">MOLINOS AGRO (S LZO)</td><td class="column-2">40</td><td class="column-3">36</td><td class="column-4">35</td><td class="column-5">18</td><td class="column-6">17</td><td class="column-7">17</td><td class="column-8">11</td><td class="column-9">12</td><td class="column-10">12</td><td class="column-11">12</td><td class="column-12"></td><td class="column-13">0.3</td><td class="column-14">5</td><td class="column-15">53</td><td class="column-16">43</td><td class="column-17">40</td><td class="column-18">42</td><td class="column-19">54</td><td class="column-20">56</td><td class="column-21">67</td><td class="column-22">69</td><td class="column-23">94</td><td class="column-24">129</td><td class="column-25">149</td><td class="column-26">219</td><td class="column-27">279</td>
                        </tr>
                        <tr class="row-13 odd">
                            <td class="column-1">VICENTIN (PUERTO)</td><td class="column-2">40</td><td class="column-3">36</td><td class="column-4">35</td><td class="column-5">18</td><td class="column-6">17</td><td class="column-7">17</td><td class="column-8">11</td><td class="column-9">12</td><td class="column-10">12</td><td class="column-11">12</td><td class="column-12">0.3</td><td class="column-13"></td><td class="column-14">5.3</td><td class="column-15">53</td><td class="column-16">43</td><td class="column-17">40</td><td class="column-18">42</td><td class="column-19">54</td><td class="column-20">54</td><td class="column-21">67</td><td class="column-22">69</td><td class="column-23">94</td><td class="column-24">129</td><td class="column-25">149</td><td class="column-26">219</td><td class="column-27">279</td>
                        </tr>
                        <tr class="row-14 even">
                            <td class="column-1">VICENTIN (FABRICA)</td><td class="column-2">35</td><td class="column-3">31</td><td class="column-4">30</td><td class="column-5">13</td><td class="column-6">12</td><td class="column-7">12</td><td class="column-8">8</td><td class="column-9">9</td><td class="column-10">9</td><td class="column-11">9</td><td class="column-12">5</td><td class="column-13">5.3</td><td class="column-14"></td><td class="column-15">48</td><td class="column-16">38</td><td class="column-17">35</td><td class="column-18">37</td><td class="column-19">49</td><td class="column-20">51</td><td class="column-21">62</td><td class="column-22">64</td><td class="column-23">90</td><td class="column-24">124</td><td class="column-25">144</td><td class="column-26">214</td><td class="column-27">274</td>
                        </tr>
                        <tr class="row-15 odd">
                            <td class="column-1">MOLCA (ROSARIO)</td><td class="column-2">81</td><td class="column-3">77</td><td class="column-4">76</td><td class="column-5">59</td><td class="column-6">58</td><td class="column-7">58</td><td class="column-8">54</td><td class="column-9">55</td><td class="column-10">55</td><td class="column-11">53</td><td class="column-12">53</td><td class="column-13">53</td><td class="column-14">48</td><td class="column-15"></td><td class="column-16">35</td><td class="column-17">12</td><td class="column-18">14</td><td class="column-19">26</td><td class="column-20">28</td><td class="column-21">41</td><td class="column-22">43</td><td class="column-23">68</td><td class="column-24">93</td><td class="column-25">113</td><td class="column-26">183</td><td class="column-27">243</td>
                        </tr>
                        <tr class="row-16 even">
                            <td class="column-1">BUNGE (SAN JERONIMO)</td><td class="column-2">72</td><td class="column-3">68</td><td class="column-4">67</td><td class="column-5">50</td><td class="column-6">49</td><td class="column-7">49</td><td class="column-8">44</td><td class="column-9">45</td><td class="column-10">45</td><td class="column-11">43</td><td class="column-12">43</td><td class="column-13">43</td><td class="column-14">38</td><td class="column-15">35</td><td class="column-16"></td><td class="column-17">29</td><td class="column-18">31</td><td class="column-19">43</td><td class="column-20">45</td><td class="column-21">58</td><td class="column-22">60</td><td class="column-23">75</td><td class="column-24">100</td><td class="column-25">120</td><td class="column-26">190</td><td class="column-27">250</td>
                        </tr>
                        <tr class="row-17 odd">
                            <td class="column-1">PLAYA PEREZ</td><td class="column-2">69</td><td class="column-3">65</td><td class="column-4">64</td><td class="column-5">47</td><td class="column-6">46</td><td class="column-7">46</td><td class="column-8">41</td><td class="column-9">42</td><td class="column-10">42</td><td class="column-11">40</td><td class="column-12">40</td><td class="column-13">40</td><td class="column-14">35</td><td class="column-15">12</td><td class="column-16">29</td><td class="column-17"></td><td class="column-18">4</td><td class="column-19">16</td><td class="column-20">18</td><td class="column-21">31</td><td class="column-22">33</td><td class="column-23">58</td><td class="column-24">93</td><td class="column-25">113</td><td class="column-26">183</td><td class="column-27">244</td>
                        </tr>
                        <tr class="row-18 even">
                            <td class="column-1">MOLINOS AGRO (ROSARIO)</td><td class="column-2">71</td><td class="column-3">67</td><td class="column-4">66</td><td class="column-5">49</td><td class="column-6">48</td><td class="column-7">48</td><td class="column-8">33</td><td class="column-9">44</td><td class="column-10">44</td><td class="column-11">42</td><td class="column-12">42</td><td class="column-13">42</td><td class="column-14">37</td><td class="column-15">14</td><td class="column-16">31</td><td class="column-17">4</td><td class="column-18"></td><td class="column-19">15</td><td class="column-20">16</td><td class="column-21">28</td><td class="column-22">30</td><td class="column-23">55</td><td class="column-24">80</td><td class="column-25">100</td><td class="column-26">170</td><td class="column-27">230</td>
                        </tr>
                        <tr class="row-19 odd">
                            <td class="column-1">UNIDAD 6 Y 7</td><td class="column-2">83</td><td class="column-3">79</td><td class="column-4">78</td><td class="column-5">61</td><td class="column-6">60</td><td class="column-7">60</td><td class="column-8">52</td><td class="column-9">56</td><td class="column-10">56</td><td class="column-11">54</td><td class="column-12">54</td><td class="column-13">54</td><td class="column-14">49</td><td class="column-15">26</td><td class="column-16">43</td><td class="column-17">16</td><td class="column-18">15</td><td class="column-19"></td><td class="column-20">14</td><td class="column-21">27</td><td class="column-22">29</td><td class="column-23">58</td><td class="column-24">96</td><td class="column-25">113</td><td class="column-26">183</td><td class="column-27">244</td>
                        </tr>
                        <tr class="row-20 even">
                            <td class="column-1">CARGILL (ALVEAR)</td><td class="column-2">85</td><td class="column-3">81</td><td class="column-4">80</td><td class="column-5">63</td><td class="column-6">62</td><td class="column-7">62</td><td class="column-8">55</td><td class="column-9">58</td><td class="column-10">58</td><td class="column-11">56</td><td class="column-12">56</td><td class="column-13">54</td><td class="column-14">51</td><td class="column-15">28</td><td class="column-16">45</td><td class="column-17">18</td><td class="column-18">16</td><td class="column-19">14</td><td class="column-20"></td><td class="column-21">13</td><td class="column-22">15</td><td class="column-23">42</td><td class="column-24">67</td><td class="column-25">87</td><td class="column-26">157</td><td class="column-27">217</td>
                        </tr>
                        <tr class="row-21 odd">
                            <td class="column-1">LDC (LAGOS)</td><td class="column-2">96</td><td class="column-3">92</td><td class="column-4">91</td><td class="column-5">74</td><td class="column-6">73</td><td class="column-7">73</td><td class="column-8">68</td><td class="column-9">69</td><td class="column-10">69</td><td class="column-11">67</td><td class="column-12">67</td><td class="column-13">67</td><td class="column-14">62</td><td class="column-15">41</td><td class="column-16">58</td><td class="column-17">31</td><td class="column-18">28</td><td class="column-19">27</td><td class="column-20">13</td><td class="column-21"></td><td class="column-22">2</td><td class="column-23">29</td><td class="column-24">54</td><td class="column-25">74</td><td class="column-26">144</td><td class="column-27">204</td>
                        </tr>
                        <tr class="row-22 even">
                            <td class="column-1">ADM AGRO (ARROYO SECO)</td><td class="column-2">101</td><td class="column-3">94</td><td class="column-4">93</td><td class="column-5">76</td><td class="column-6">75</td><td class="column-7">75</td><td class="column-8">70</td><td class="column-9">71</td><td class="column-10">71</td><td class="column-11">69</td><td class="column-12">69</td><td class="column-13">69</td><td class="column-14">64</td><td class="column-15">43</td><td class="column-16">60</td><td class="column-17">33</td><td class="column-18">30</td><td class="column-19">29</td><td class="column-20">15</td><td class="column-21">2</td><td class="column-22"></td><td class="column-23">27</td><td class="column-24">52</td><td class="column-25">72</td><td class="column-26">142</td><td class="column-27">202</td>
                        </tr>
                        <tr class="row-23 odd">
                            <td class="column-1">V. CONSTITUCION</td><td class="column-2">133</td><td class="column-3">129</td><td class="column-4">128</td><td class="column-5">111</td><td class="column-6">100</td><td class="column-7">100</td><td class="column-8">95</td><td class="column-9">96</td><td class="column-10">96</td><td class="column-11">94</td><td class="column-12">94</td><td class="column-13">94</td><td class="column-14">90</td><td class="column-15">68</td><td class="column-16">75</td><td class="column-17">58</td><td class="column-18">55</td><td class="column-19">58</td><td class="column-20">42</td><td class="column-21">29</td><td class="column-22">27</td><td class="column-23"></td><td class="column-24">25</td><td class="column-25">45</td><td class="column-26">115</td><td class="column-27">175</td>
                        </tr>
                        <tr class="row-24 even">
                            <td class="column-1">SAN NICOLAS</td><td class="column-2">158</td><td class="column-3">154</td><td class="column-4">153</td><td class="column-5">136</td><td class="column-6">135</td><td class="column-7">135</td><td class="column-8">130</td><td class="column-9">131</td><td class="column-10">131</td><td class="column-11">129</td><td class="column-12">129</td><td class="column-13">129</td><td class="column-14">124</td><td class="column-15">93</td><td class="column-16">100</td><td class="column-17">93</td><td class="column-18">80</td><td class="column-19">96</td><td class="column-20">67</td><td class="column-21">54</td><td class="column-22">52</td><td class="column-23">25</td><td class="column-24"></td><td class="column-25">20</td><td class="column-26">90</td><td class="column-27">150</td>
                        </tr>
                        <tr class="row-25 odd">
                            <td class="column-1">BUENGE (RAMALLO)</td><td class="column-2">178</td><td class="column-3">174</td><td class="column-4">173</td><td class="column-5">156</td><td class="column-6">155</td><td class="column-7">155</td><td class="column-8">150</td><td class="column-9">151</td><td class="column-10">151</td><td class="column-11">149</td><td class="column-12">149</td><td class="column-13">149</td><td class="column-14">144</td><td class="column-15">113</td><td class="column-16">120</td><td class="column-17">113</td><td class="column-18">100</td><td class="column-19">113</td><td class="column-20">87</td><td class="column-21">74</td><td class="column-22">72</td><td class="column-23">45</td><td class="column-24">20</td><td class="column-25"></td><td class="column-26">70</td><td class="column-27">130</td>
                        </tr>
                        <tr class="row-26 even">
                            <td class="column-1">SAN PEDRO</td><td class="column-2">248</td><td class="column-3">244</td><td class="column-4">243</td><td class="column-5">226</td><td class="column-6">225</td><td class="column-7">220</td><td class="column-8">220</td><td class="column-9">221</td><td class="column-10">221</td><td class="column-11">219</td><td class="column-12">219</td><td class="column-13">219</td><td class="column-14">214</td><td class="column-15">183</td><td class="column-16">190</td><td class="column-17">183</td><td class="column-18">170</td><td class="column-19">183</td><td class="column-20">157</td><td class="column-21">144</td><td class="column-22">142</td><td class="column-23">115</td><td class="column-24">90</td><td class="column-25">70</td><td class="column-26"></td><td class="column-27">60</td>
                        </tr>
                        <tr class="row-27 odd">
                            <td class="column-1">COFCO (LIMA)</td><td class="column-2">308</td><td class="column-3">304</td><td class="column-4">303</td><td class="column-5">286</td><td class="column-6">285</td><td class="column-7">280</td><td class="column-8">280</td><td class="column-9">281</td><td class="column-10">281</td><td class="column-11">279</td><td class="column-12">279</td><td class="column-13">279</td><td class="column-14">274</td><td class="column-15">243</td><td class="column-16">250</td><td class="column-17">244</td><td class="column-18">230</td><td class="column-19">244</td><td class="column-20">217</td><td class="column-21">204</td><td class="column-22">202</td><td class="column-23">175</td><td class="column-24">150</td><td class="column-25">130</td><td class="column-26">60</td><td class="column-27"></td>
                        </tr>
                    </tbody>
                </table>

                <p className="big">*LAS DISTANCIAS ESTAN SUJETAS A VARIACION DEPENDIENDO LA RUTA Y/O CAMINO QUE TOME CADA TRANSPORTISTA</p>
                
            </div>
            
            <PreFooter />
        </div>
    )
}

export default DistanciaEP
